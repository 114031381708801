'use es6';

import { makeGqlEarlyRequest } from 'apollo-link-hub-http/quickFetch';
import { isObjectTypeId, ObjectTypesToIds, ObjectTypeFromIds } from 'customer-data-objects/constants/ObjectTypeIds';
import { getPathname } from '../quick-fetch/utils';
import { FetchHighlightCard } from 'card-preview-highlight-lib/gqlEarlyQueries';
import { cardLocationQuery, fetchLegacySidebarSubject } from 'crm-cards/gqlEarlyQueries';
import { fetchActivityButtons } from 'crm-activity-creator-widgets/gqlEarlyQueries';
import { fetchTimelineFilters } from 'crm-events-data/gqlEarlyQueries';
export const RIGHT_SIDEBAR_PREF_KEY = 'CRM:Sidebar:right-sidebar-closed';
export const doGraphQlQuickFetch = () => {
  try {
    (function () {
      const matches = getPathname().match(/contacts\/\d+\/(contact|company|deal|ticket|record\/\d+-\d+)?\/(\d+)/);
      if (!matches) {
        return;
      }
      let objectType = matches[1].toUpperCase();
      if (objectType.includes('RECORD')) {
        objectType = objectType.split('/')[1];
        objectType = ObjectTypeFromIds[objectType] || objectType;
      }
      const objectTypeId = isObjectTypeId(objectType) ? objectType : ObjectTypesToIds[objectType];
      const subjectId = parseInt(matches[2], 10);
      if (objectType && subjectId) {
        const url = window.quickFetch.getApiUrl('/graphql/crm', true);
        makeGqlEarlyRequest({
          url,
          operation: {
            operationName: 'fetchLegacySidebarSubject',
            query: fetchLegacySidebarSubject,
            variables: {
              objectId: `${subjectId}`,
              objectTypeId
            }
          }
        });
        if (window.localStorage && window.localStorage.getItem(RIGHT_SIDEBAR_PREF_KEY) !== 'true') {
          makeGqlEarlyRequest({
            url,
            operation: {
              operationName: 'cardLocationQuery',
              query: cardLocationQuery,
              variables: {
                objectId: subjectId,
                objectTypeId,
                location: 'CRM_RECORD_RIGHT'
              }
            }
          });
        }
        makeGqlEarlyRequest({
          url,
          operation: {
            operationName: 'cardLocationQuery',
            query: cardLocationQuery,
            variables: {
              objectId: subjectId,
              objectTypeId,
              location: 'CRM_RECORD_MIDDLE'
            }
          }
        });
        makeGqlEarlyRequest({
          url,
          operation: {
            operationName: 'cardLocationQuery',
            query: cardLocationQuery,
            variables: {
              objectId: subjectId,
              objectTypeId,
              location: 'CRM_RECORD_LEFT'
            }
          }
        });
        const permissionVariables = {
          subjectId,
          objectType
        };
        makeGqlEarlyRequest({
          url,
          operation: {
            operationName: 'SubjectPermissionsQuery',
            query: importGql('SubjectPermissionsQuery'),
            variables: permissionVariables
          }
        });

        // Simplified version of https://git.hubteam.com/HubSpot/customer-data-objects/blob/master/static/js/model/ImmutableModel.ts#L93
        const isHighlightWithPipeline = ['DEAL', 'TICKET'].includes(objectType);
        const highlightCardVariables = {
          isPipelineable: isHighlightWithPipeline,
          objectType,
          subjectId
        };
        makeGqlEarlyRequest({
          url,
          operation: {
            operationName: 'FetchHighlightCard',
            query: FetchHighlightCard,
            variables: highlightCardVariables
          }
        });
        makeGqlEarlyRequest({
          url,
          operation: {
            operationName: 'fetchActivityButtons',
            query: fetchActivityButtons,
            variables: {
              objectTypeId,
              subjectId
            }
          }
        });
        makeGqlEarlyRequest({
          url,
          operation: {
            operationName: 'fetchTimelineFilters',
            query: fetchTimelineFilters,
            variables: {
              subjectId,
              objectTypeId
            }
          }
        });
      }
    })();
  } catch (e) {
    // Ignore
  }
};
doGraphQlQuickFetch();