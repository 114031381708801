import { getFrameworkDataQuickFetchClient } from 'framework-data-schema-quick-fetch';
import { APP_SETTINGS_TO_FETCH } from '../frameworkAppSettings/AppSettings';
import { getPathname } from './utils';

export const doQuickFetchRecord = () => {
  try {
    (function () {
      const pathname = getPathname();
      const matches = pathname.match(
        /contacts\/\d+\/(contact|company|deal|ticket|record\/\d+-\d+)?\/(\d+)/
      );

      if (!matches) {
        return;
      }

      let objectType = matches[1].toUpperCase();
      if (objectType.includes('RECORD')) {
        objectType = objectType.split('/')[1];
      }

      var isViewingAllProperties = pathname.match(/properties/);
      var shouldFetchTimeline = !isViewingAllProperties;

      var subjectId = matches[2];
      var shouldFetchContact =
        objectType === 'contact' || (objectType === '0-1' && subjectId);

      if (shouldFetchTimeline) {
        window.quickFetch.makeEarlyRequest('timeline', {
          url: window.quickFetch.getApiUrl(
            '/timeline/v2/object/' +
              objectType +
              '/' +
              subjectId +
              '?limit=20&renderingRequested=true',
            true
          ),
          timeout: 5000,
          dataType: 'json',
        });

        window.quickFetch.makeEarlyRequest('quickFetchIntegrationEvents', {
          url: window.quickFetch.getApiUrl(
            '/integrators/timeline/v1/portal-installs/apps-with-event-types',
            true
          ),
          timeout: 5000,
          dataType: 'json',
        });

        window.quickFetch.makeEarlyRequest(
          'quickFetchInstalledHubSpotOwnedIntegrations',
          {
            url: window.quickFetch.getApiUrl(
              '/integrators/v1/portal-installs/hubspot-owned',
              true
            ),
            timeout: 5000,
            dataType: 'json',
          }
        );
      }

      if (shouldFetchContact) {
        //This is to match the values that the ContactsAPI would send.
        //The values from crm_data/static/js/contacts/api/ContactsExtraParams.js, except vid
        //These must match what ContactsAPI wants to use for its request to be a valid quickfetch
        const queryParmArray = [
          'formSubmissionMode=none',
          'identityProfileMode=all',
          'propertyMode=value_only',
          'resolveAssociations=false',
          'resolveOwner=false',
          'showAnalyticsDetails=false',
          'showListMemberships=true',
          'showPastListMemberships=false',
          'showPublicToken=false',
          'showSourceMetadata=false',
          `vid=${subjectId}`,
          'flpViewValidation=true',
        ];

        const contactBatch = `/contacts/v1/contact/vids/batch/?${queryParmArray.join(
          '&'
        )}`;

        const quickFetchKey = `quickFetchContact:${subjectId}:${queryParmArray
          .sort()
          .join('&')}`;

        window.quickFetch.makeEarlyRequest(quickFetchKey, {
          url: window.quickFetch.getApiUrl(contactBatch, true),
          timeout: 5000,
          dataType: 'json',
        });
      }

      getFrameworkDataQuickFetchClient()
        .then((client) => {
          client.typeMetadata.get({
            appSettingNames: APP_SETTINGS_TO_FETCH,
          });
        })
        .catch((error) => {
          throw new Error(error);
        });

      // CrmRecordsPage Framework App Setting determines if we should display the record page for the
      // supplied object type. Cobjects (portal specific object types 2-#), are always supported.
      const shouldFetchCrmRecordsPageFAS = !objectType.match(/^(2-\d+)$/);

      if (shouldFetchCrmRecordsPageFAS) {
        const fASQuery = `/framework-builder/v1/read/metadata/type/${objectType}/crmRecordsPage`;
        window.quickFetch.makeEarlyRequest('record:FAS', {
          url: window.quickFetch.getApiUrl(fASQuery, true),
          timeout: 5000,
          dataType: 'json',
        });
      }
    })();
  } catch (e) {
    console.error('quickFetchRecord', e);
  }
};

doQuickFetchRecord();
